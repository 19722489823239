<template>
  <v-container>
    <!-- TODO: cleanup this stuff -->
    <v-card dark class="overflow-hidden">

      <v-select :items="seasonsMapped" item-text="displayName" item-value="id" label="Season"></v-select>

      <!-- <v-data-table :headers="seasonsHeaders" :items="seasons"></v-data-table> -->

      <pre><code>{{ seasonsMapped }}</code></pre>

    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { Organization, League, Season, User } from '@/models/orm/Hierarchy';

export default {
  name: 'ListOrganizations',

  data() {
    return {
      organizationsHeaders: [
        { text: 'Id', value: 'id' },
        { text: 'Code', value: 'code' },
        { text: 'Name', value: 'displayName' },
      ],
      leaguesHeaders: [
        { text: 'Id', value: 'id' },
        { text: 'Code', value: 'code' },
        { text: 'Name', value: 'displayName' },
      ],
      seasonsHeaders: [
        { text: 'Id', value: 'id' },
        { text: 'Name', value: 'displayName' },
        { text: 'League', value: 'league.displayName' },
        { text: 'Start', value: 'startDate' },
        { text: 'End', value: 'endDate' },
      ],
    };
  },

  computed: {
    ...mapGetters(['currentProfile']),

    users() {
      return User.all();
    },

    organizations() {
      return Organization.all();
    },

    leagues() {
      return League.query().has('seasons').with('organization|seasons').all();
    },

    seasons() {
      return Season.query().with('league').all();
    },

    seasonsMapped() {
      return this.leagues.reduce((prev, cur, i) => {
        if (Array.isArray(prev)) {
          prev.push({ header: cur.displayName });
          prev = [...prev, ...cur.seasons];
        }
        return prev;
      }, []);
    },
  },
};
</script>
